<template>
	<div v-if="!user?.avatar" :class="computedClass">
		{{ user?.personalInfo.firstName[0] }}
		{{ user?.personalInfo.lastName && ` ${user?.personalInfo.lastName[0]}` }}
	</div>
	<img v-if="user?.avatar" :src="user?.avatar" alt="User avatar" :class="computedAvatarClass" />
</template>

<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'

const { user } = useUserStore()
const attrs = useAttrs()
const props = defineProps<{
	avatarClass?: string
}>()

const computedClass = computed(() => {
	return twMerge(
		'flex items-center justify-center text-white w-12 h-12 rounded-full bg-zinc-600 uppercase',
		attrs.class as string
	)
})

const computedAvatarClass = computed(() => {
	return twMerge('w-12 h-12 rounded-full object-cover', props.avatarClass as string)
})
</script>
