import { LogOut, Shield, ShoppingBagIcon, UserRound } from 'lucide-vue-next'
import { UserRole } from '~/types/models/user.interface'

interface MenuItem {
	label: string
	href: string
	icon: any
	allowedRoles: UserRole[]
}

export const loggedMenuItems: MenuItem[] = [
	{
		label: 'Meu painel',
		href: '/profile/dashboard',
		icon: UserRound,
		allowedRoles: [UserRole.STREAMER],
	},
	{
		label: 'Meus pedidos',
		href: '/profile/orders',
		allowedRoles: [UserRole.USER, UserRole.STREAMER],
		icon: ShoppingBagIcon,
	},
	{
		label: 'Admin',
		href: '/admin',
		allowedRoles: [UserRole.ADMIN],
		icon: Shield,
	},
]
